import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import "../styles/Contact.scss";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'YOUR_SERVICE_ID', // Zastąp własnym Service ID
        'YOUR_TEMPLATE_ID', // Zastąp własnym Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          subject: formData.subject,
          message: formData.message,
          to_email: 'coloresdefuerteventura@gmail.com',
        },
        'YOUR_PUBLIC_KEY' // Zastąp własnym Public Key
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setStatus('Wiadomość wysłana pomyślnie!');
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
        },
        (err) => {
          console.error('FAILED...', err);
          setStatus('Wystąpił błąd podczas wysyłania wiadomości.');
        }
      );
  };

  return (
    <div className="contact-form-container">
      <h2>Formularz kontaktowy</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-item'>
          <label htmlFor="name">Imię i nazwisko:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '8px' }}
          />
        </div>
        <div className='form-item'>
          <label htmlFor="email">Adres e-mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '8px' }}
          />
        </div>
        <div className='form-item'>
          <label htmlFor="subject">Temat wiadomości:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            style={{ width: '100%', padding: '8px' }}
          />
        </div>
        <div className='form-item'>
          <label htmlFor="message">Treść wiadomości:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="5"
            style={{ width: '100%', padding: '8px' }}
          ></textarea>
        </div>
        <button type="submit" style={{ padding: '10px 20px', cursor: 'pointer' }}>
          Wyślij
        </button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default ContactForm;
