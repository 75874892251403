import { useTranslation, Trans } from "react-i18next";
import "../styles/styles.scss";
import i18n from "i18next";
import "../styles/Contact.scss";
import ContactForm from "./ContactForm";
import "material-icons/iconfont/material-icons.css";
import facebookIcon from "../assets/facebook-icon.png";
import logo from "../assets/logo3.png";

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="contact-wrapper">
      <div className="contact-container">
        <div>
          Jeśli masz pytania, potrzebujesz pomocy lub chcesz się z nami
          skontaktować, oto jak możesz to zrobić:
        </div>
        <div className="contact-descriptions-container">
          <div className="contact-details">
            <img className="logo" src={logo}></img>
            <div className="contact-subtitle">
              <div>Dane kontaktowe</div>
            </div>
            <div className="wrapper">
              <div className="contact">
                <div className="contact-details-wrapper">
                  <span className="material-icons-outlined">mail</span>
                  Adres e-mail:
                </div>
                <span className="mail">coloresdefuerteventure@gmail.com</span>
              </div>
              <div className="contact">
                <div className="contact-details-wrapper">
                  <span class="material-icons-outlined">phone_iphone</span>
                  Numer telefonu:
                </div>
                <span className="telephone">+34 697 30 89 89</span>
              </div>
              <div className="contact">
                <div className="contact-details-wrapper">
                  <img className="icon" src={facebookIcon}></img>
                  Facebook:
                </div>
                <span className="facebook">
                  <a
                    className="link"
                    href="https://www.facebook.com/profile.php?id=61558605133943"
                  >
                    Fuerteventura.Costa.Calma
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div>
            <ContactForm />
          </div>

          {/* <div>
          <Trans i18nKey="Climate_description_1" 
            components={{
              }}/>
          </div>
          <div className="">
          <Trans i18nKey="Climate_description_2" 
            components={{
              }}/>
          </div>
          <div>
          <Trans i18nKey="Climate_description_3" 
            components={{
              }}/>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;
